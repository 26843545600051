import { Component, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { Validators } from '@angular/forms';
import {
    BehaviorSubject,
    Observable,
    Subject,
    Subscription,
    combineLatest,
    debounceTime,
    delay,
    filter,
    iif,
    map,
    of,
    skip,
    startWith,
    switchMap,
    tap,
} from 'rxjs';
import { AutosuggestFacade } from '@app/store/heremaps';
import { CountriesFacade } from '@app/store/countries';
import { AutosuggestItem } from '@app/store/heremaps';
import { AutoComplete, AutoCompleteSelectEvent } from 'primeng/autocomplete';
import { ShowHide } from '@app/shared/animations';
import { UserFacade } from '@app/modules';
import { Roles } from '@app/modules/permissions';
import { FormService } from '../../services/form.service';
import { GeoPositionModel } from '@app/store/planning/models';

@Component({
    selector: 'app-form-autosuggest-input-city',
    styleUrls: ['./form-autosuggest-input-city.component.scss'],
    templateUrl: './form-autosuggest-input-city.component.html',
    animations: [ShowHide],
})
export class FormAutosuggestInputCityComponent implements OnInit, OnDestroy {
    private readonly formService = inject(FormService);
    private readonly autosuggestFacade = inject(AutosuggestFacade);
    private readonly countriesFacade = inject(CountriesFacade);
    private readonly userFacade = inject(UserFacade);

    @ViewChild('autocomplete') autocomplete?: AutoComplete;
    @Input() placeholder?: string;

    items$!: Observable<AutosuggestItem[]>;
    loading$!: Observable<boolean>;
    hasGeoposition$!: Observable<boolean>;
    areFavouriteAddressesAvailable = this.userFacade.state.user?.role === Roles.ROLE_PARTNER_EMPLOYEE;

    get form() {
        return this.formService.form;
    }
    city$!: Observable<string>;
    street$!: Observable<string>;
    geoPosition$!: Observable<GeoPositionModel>;
    isCountryRestricted$!: Observable<boolean>;

    selected$ = new Subject<void>();

    private _blur$ = new Subject<boolean>();
    blur$ = this._blur$.pipe(debounceTime(100));
    focus$ = new BehaviorSubject(null);

    @Output() blurEvent = this.blur$;

    private readonly subscriptions$ = new Subscription();

    ngOnInit() {
        this.city$ = combineLatest([this.formService.form.controls.city.valueChanges.pipe(startWith(true)), this.focus$]).pipe(
            filter(() => typeof this.formService.form.controls.term.getRawValue() === 'string'),
            map(() => this.formService.form.controls.city.getRawValue()?.trim() || ''),
        );

        this.street$ = this.formService.form.controls.street.valueChanges.pipe(
            startWith(true),
            map(() => this.formService.form.controls.street.getRawValue() || ''),
        );

        this.geoPosition$ = this.formService.form.controls.geoPosition.valueChanges.pipe(
            startWith(true),
            map(() => this.formService.form.controls.geoPosition.getRawValue()),
        );

        this.isCountryRestricted$ = this.formService.form.controls.countryRestricted.valueChanges.pipe(
            startWith(true),
            map(() => this.formService.form.controls.countryRestricted.getRawValue()),
        );

        this.items$ = combineLatest([this.city$, this.isCountryRestricted$, this.countriesFacade.availableCode3$]).pipe(
            switchMap(([term, restricted, countries]) =>
                iif(() => typeof term === 'string' && term !== '', this.autosuggestFacade.items$(term, restricted ? countries : []), of([])),
            ),
        );

        this.loading$ = combineLatest([this.city$, this.isCountryRestricted$, this.countriesFacade.availableCode3$]).pipe(
            switchMap(([term, restricted, countries]) => this.autosuggestFacade.loading$(term, restricted ? countries : [])),
        );

        this.subscriptions$.add(
            combineLatest([this.city$.pipe(skip(1), debounceTime(0)), this.isCountryRestricted$, this.countriesFacade.availableCode3$])
                .pipe(
                    filter(([term]) => typeof term === 'string'),
                    debounceTime(300),
                    tap(([term, restricted, countries]) => this.autosuggestFacade.getAutosuggests(term, restricted ? countries : [])),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.city$
                .pipe(
                    filter((term) => typeof term === 'string' && term !== ''),
                    tap(() => this.autocomplete?.show()),
                )
                .subscribe(),
        );

        this.subscriptions$.add(
            this.selected$
                .pipe(
                    delay(10),
                    tap(() => {
                        this.autocomplete?.hide();
                        this.autocomplete?.inputEL?.nativeElement.blur();
                    }),
                )
                .subscribe(),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions$.unsubscribe();
    }

    onSelect(event: AutoCompleteSelectEvent) {
        const item: AutosuggestItem = event.value;

        const city = `${
            item.address.district && item.address.district !== item.address.city ? item.address.district + ', ' + item.address.city : item.address.city
        }`;
        this.form.patchValue(
            {
                id: item.id,
                isPoint: false,
                country: item.address.countryCode,
                city: city,
                postalCode: item.address.postalCode,
                geoPosition: {
                    longitude: item.position.lng,
                    latitude: item.position.lat,
                },
            },
            { emitEvent: false },
        );

        this.selected$.next();
    }

    onBlur() {
        this._blur$.next(true);
    }

    onFocus() {
        const value = this.formService.form.controls.city.getRawValue() ?? '';
        if (value !== '') {
            this.focus$.next(null);
            this.autocomplete?.show();
        }
    }

    get required(): boolean {
        return this.formService.form.controls.term.hasValidator(Validators.required);
    }
}
